<template>
  <div>
    <v-container>
      <v-row style="height:60px" no-gutters class="mt-2">
        <v-col style="width:200px" cols="auto">
          <DateFind class="mb-n3" label="Fecha" v-model="fecha"></DateFind>

          <!-- <v-text-field
            outlined
            dense
            v-model="fecha"
            label="Fecha"
            style="height:40px"
            type="date"
            class="mr-2"
          ></v-text-field> -->
        </v-col>

        <template v-if="$store.state.esDepartamento">
          <v-col>
            <v-autocomplete
              v-model="per"
              dense="true"
              hide-selected
              :items="itemsPer"
              :search-input.sync="searchPer"
              item-value="PerId"
              item-text="PerNom"
              outlined
              no-data-text="Introduzca texto a buscar"
              label="Empleado"
              cache-items="true"
              return-object="true"
              ref="perCtrl"
              @change="cargar"
            ></v-autocomplete>
          </v-col>
        </template>

        <v-col style="width: 120px" cols="auto">
          <v-btn
            color="primary"
            class="ml-2"
            :disabled="loading"
            @click="procesarCuadrantes(true)"
            >
              Mostrar
          </v-btn>
        </v-col>
        <!-- <v-col style="width:160px" cols="auto">
          <v-btn
            color="default"
            class="ml-2"
            @click="descargarPDF"
            >
              Descargar PDF
          </v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">TIENDA</th>
                  <th class="text-center">{{titDias.Lunes}}</th>
                  <th class="text-center">{{titDias.Martes}}</th>
                  <th class="text-center">{{titDias.Miercoles}}</th>
                  <th class="text-center">{{titDias.Jueves}}</th>
                  <th class="text-center">{{titDias.Viernes}}</th>
                  <th class="text-center">{{titDias.Sabado}}</th>
                  <th class="text-center">{{titDias.Domingo}}</th>
                  <th class="text-center">SEMANA</th>
                </tr>              
              </thead>
              <tbody>
                <tr style="background-color: white" v-for="item in cuadrante" :key="item.name" v-bind:style="item.ResumenFecha ? 'font-weight:bold' : 'font-weight:normal'">
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; min-width: 250px"> {{ item.Tienda }} </td>
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Lunes.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-if="item.Lunes.Horas != null">Horas:</span> {{ item.Lunes.Horas }}</td>
                      </tr>
                      <tr style="background-color: white">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Lunes.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Martes.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-if="item.Martes.Horas != null">Horas:</span> {{ item.Martes.Horas }}</td>
                      </tr>
                      <tr style="background-color: white">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Martes.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Miercoles.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-if="item.Miercoles.Horas != null">Horas:</span> {{ item.Miercoles.Horas }}</td>
                      </tr>
                      <tr style="background-color: white">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Miercoles.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Jueves.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-if="item.Jueves.Horas != null">Horas:</span> {{ item.Jueves.Horas }}</td>
                      </tr>
                      <tr style="background-color: white">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Jueves.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Viernes.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: white">
                        <td style="text-align:center; border:0px"><span v-if="item.Viernes.Horas != null">Horas:</span> {{ item.Viernes.Horas }}</td>
                      </tr>
                      <tr style="background-color: white">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Viernes.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="background-color: mistyrose; border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: mistyrose">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Sabado.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: mistyrose">
                        <td style="text-align:center; border:0px"><span v-if="item.Sabado.Horas != null">Horas:</span> {{ item.Sabado.Horas }}</td>
                      </tr>
                      <tr style="background-color: mistyrose">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Sabado.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="background-color: mistyrose; border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px">
                    <table>
                      <tr style="background-color: mistyrose">
                        <td style="text-align:center; border:0px"><span v-html="formatHoras(item.Domingo.Horario)"></span></td>
                      </tr>
                      <tr style="background-color: mistyrose">
                        <td style="text-align:center; border:0px"><span v-if="item.Domingo.Horas != null">Horas:</span> {{ item.Domingo.Horas }}</td>
                      </tr>
                      <tr style="background-color: mistyrose">
                          <!-- <td style="text-align:center; font-weight:bold; border:0px">{{ item.Domingo.Excepcion }}</td> -->
                      </tr>
                    </table>
                  </td>
                  <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; min-width: 147px; max-width: 147px; font-weight:bold">
                    <table>
                      <tr>
                        <td style="text-align:center; border:0px solid black">{{ item.TotalSemana.Horario }}</td>
                      </tr>
                      <tr>
                        <td style="text-align:center; border:0px solid black"><span v-if="item.TotalSemana.Horas != null">Horas:</span> {{ item.TotalSemana.Horas }}</td>
                      </tr>
                      <tr>
                          <td style="text-align:center; font-weight:bold; border:0px solid black">{{ item.TotalSemana.Excepcion }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>                
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto">
          <v-expansion-panels inset>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Leyenda
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td style="border-top: 1px solid lightgrey; border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">A</td>
                        <td style="border-top: 1px solid lightgrey; border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">ASUNTOS PROPIOS</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">B</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">BAJA MEDICA</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">BO</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">BODA</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">C</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">CURSO O REUNION</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">CI</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">CITACION OFICIAL</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">D</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">DESCANSO SEMANAL</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">E</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">DESCANSO POR SABADO FESTIVO</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">ES</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">SUSPENSIÓN DE EMPLEO Y SUELDO</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">F</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">FESTIVO O ABSTENCION</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">FF</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">FALLECIMIENTO FAMILIAR</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">H</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">HUELGA</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">I</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">INGRESO HOSPITALARIO</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">M</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">ASISTENCIA MEDICO</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">MU</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">MUDANZA</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">N</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">NEGOCIACION SINDICAL</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">P</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">PERMISO RETRIBUIDO</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">R</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">RECUPERACION ASUNTOS PROPIOS</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">S</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">HORAS SINDICALES</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">V</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">VACACIONES</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">X</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">ACUMULACIÓN DE HORAS COVID</td>
                      </tr>
                      <tr>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; width:20px">Z</td>
                        <td style="border-bottom: 1px solid lightgrey; border-left: 1px solid lightgrey; border-right: 1px solid lightgrey; width:300px">RECUPERACIÓN DE HORAS COVID</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>      
    </v-container>
  </div>
</template>

<script>
  import axios from "axios";
  import { mapState } from "vuex";
  import DateFind from "../components/DateFind.vue";
  import funcs from "../funcs";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz', 'esEmpleado'])
    },
    data () {
      return {
        loading: true,
        fecha: null,
        per: {},
        itemsPer: [],
        searchPer: null,
        titDias: {
          Lunes: "LUNES",
          Martes: "MARTES",
          Miercoles: "MIERCOLES",
          Jueves: "JUEVES",
          Viernes: "VIERNES",
          Sabado: "SABADO",
          Domingo: "DOMINGO"
        },
        cuadrante: []
      }
    },
    components:{
      DateFind
    },
    watch: {
      searchPer(val){
        const controllerParameters = {
          Action: "GET_DATA_LIST_PAGINATED",
          PerNom: val,
          NumRegsPag: 15,
          NumPag: 1,
          EmpId: this.empId 
        };

        const AuthToken = localStorage.getItem('token');
        axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            this.itemsPer = result.data.EntsList;
        });
      },      
    },
    async mounted() {
      this.loading = true;
      if (this.esEmpleado)
      {
          const data = await this.getPendientesFromApi();
         /*if (data.total != null && data.total > 0)
          {
            alert(`Tienes ${data.total} ${data.total > 1 ? 'documentos pendientes' : 'documento pendiente'} de firma, reviselos para poder visualizar el cuadrante.`);
            this.$router.push('/documentos-pendientes-firmar');
            return;
          }*/

          if (data.total != null && data.total > 0)
          {
            const userResponse = confirm(
              `Tienes ${data.total} ${
              data.total > 1 ? 'documentos pendientes' : 'documento pendiente'
            } de firma, ¿quieres revisarlos ahora?`
            );

            if (userResponse) {
              // Usuario hizo clic en "Aceptar"
              this.$router.push('/documentos-pendientes-firmar');

            } else {
              // Usuario hizo clic en "Cancelar"
              // Aquí puedes hacer una solicitud al backend para guardar la respuesta
              // por ejemplo, utilizando Axios o la API fetch
              await this.guardarRespuestaNoFirmar();
            }
          }

      }
      
      this.loading = false;

      this.itemsPer.push(this.$store.state.per);
      this.per = this.$store.state.per;
      const hoy = new Date();
      this.fecha =  hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();
    },
    methods: {
      procesarCuadrantes(){
        return new Promise((resolve) => {
          const controllerParameters = {
              Action: 'GET_CUADRANTE_SEMANAL_EMPLEADO',
              Fecha: this.fecha,
              PerId: this.per.PerId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
              // if (mostrarCuadrante){
              result.data.sort(this.compararArrayCuadrantes)

              this.cuadrante = [];
              let tdaAnt = '-';
              let cuadranteObj = {};
              let ultElemIndex = 0;
              const formatoHora = new Intl.NumberFormat('es-ES', {style: 'decimal', minimumFractionDigits:2, maximumFractionDigits: 2})

              let fechaSemana = new Date(result.data[0].Semana);
              const lunesStr = funcs.UtcDateTimeToStr(fechaSemana, 'date');
              const martesStr = funcs.UtcDateTimeToStr(fechaSemana.setDate(fechaSemana.getDate() + 1), 'date');
              const miercolesStr = funcs.UtcDateTimeToStr(fechaSemana.setDate(fechaSemana.getDate() + 1), 'date');
              const juevesStr = funcs.UtcDateTimeToStr(fechaSemana.setDate(fechaSemana.getDate() + 1), 'date');
              const viernesStr = funcs.UtcDateTimeToStr(fechaSemana.setDate(fechaSemana.getDate() + 1), 'date');
              const sabadoStr = funcs.UtcDateTimeToStr(fechaSemana.setDate(fechaSemana.getDate() + 1), 'date');
              const domingoStr = funcs.UtcDateTimeToStr(fechaSemana.setDate(fechaSemana.getDate() + 1), 'date');

              // this.titLunes = result.data.DiaSemana;
              this.titDias.Lunes = 'LUNES ' + lunesStr;
              this.titDias.Martes = 'MARTES ' + martesStr;
              this.titDias.Miercoles = 'MIERCOLES ' + miercolesStr;
              this.titDias.Jueves = 'JUEVES ' + juevesStr;
              this.titDias.Viernes = 'VIERNES ' + viernesStr;
              this.titDias.Sabado = 'SABADO ' + sabadoStr;
              this.titDias.Domingo = 'DOMINGO ' + domingoStr;

              // var TotalHorasSemana = 0;
              result.data.forEach(element => {
                if (element.Tienda != null && element.Tienda != '')
                {
                  if (element.Tienda != tdaAnt){
                    cuadranteObj = {Tienda: element.Tienda, Lunes: '', Martes: '', Miercoles: '', Jueves: '', Viernes: '', Sabado: '', Domingo: '', TotalSemana: '', ResumenFecha: element.ResumenFecha};
                    this.cuadrante.push(cuadranteObj);
                    ultElemIndex = this.cuadrante.length - 1;
                    // TotalHorasSemana = 0;
                  }

                  const fecha = new Date(element.Fecha);
                  const diaSemana = fecha.getDay(fecha);
                  // TotalHorasSemana += element.Horas;
  
                  // TotalHorasSemana = element.HorasSemana;

                  // var infoDiaObj = {Horario: element.TiendaDependientaFecha, Horas: formatoHora.format(element.Horas), Excepcion: element.vbpi};
                  const infoDiaObj = {Horario: element.Turnos, Horas: formatoHora.format(element.Horas), Excepcion: element.vbpi};
                  // var infoSemanaObj = {Horario: '', Horas: formatoHora.format(TotalHorasSemana), Excepcion: ''};

                  if (diaSemana == 1) { // Lunes
                    this.cuadrante[ultElemIndex].Lunes = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Lunes = element.TiendaDependientaFecha + String.fromCharCode(10) + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;

                    // var pruebaObj = {Horario: element.TiendaDependientaFecha, Horas: formatoHora.format(element.Horas), Excepcion: element.vbpi};
                    // this.cuadrante[ultElemIndex].Prueba = pruebaObj;
                  }
                  else if (diaSemana == 2){
                    this.cuadrante[ultElemIndex].Martes = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Martes = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  else if (diaSemana == 3){
                    this.cuadrante[ultElemIndex].Miercoles = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Miercoles = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  else if (diaSemana == 4){
                    this.cuadrante[ultElemIndex].Jueves = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Jueves = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  else if (diaSemana == 5){
                    this.cuadrante[ultElemIndex].Viernes = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Viernes = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  else if (diaSemana == 6){
                    this.cuadrante[ultElemIndex].Sabado = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Sabado = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  else if (diaSemana == 0 && !element.ResumenSemana){
                    this.cuadrante[ultElemIndex].Domingo = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Domingo = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  else if (diaSemana == 0 && element.ResumenSemana){
                    this.cuadrante[ultElemIndex].TotalSemana = infoDiaObj;
                    // this.cuadrante[ultElemIndex].Domingo = element.TiendaDependientaFecha + "     Horas: " + formatoHora.format(element.Horas) + "\r\n" + element.vbpi;
                  }
                  // this.cuadrante[ultElemIndex].TotalSemana = infoSemanaObj;
                  // this.cuadrante[ultElemIndex].TotalSemana = "     Horas: " + formatoHora.format(TotalHorasSemana);

                  tdaAnt = element.Tienda;
                }
              });
              // }
              resolve(true);
          })});
      },
      // descargarPDF () {
      //   this.procesarCuadrantes(false).then(res =>{
      //     var descarga = document.createElement("a");
      //     descarga.href = this.urlRaiz + "/Files/CuadranteSemanal.pdf" ;
      //     descarga.target = "_blank";
      //     descarga.download = "CuadranteSemanal.pdf";
      //     var clickEvent = new MouseEvent('click', { 'view':window, 'bubbles': true, 'cancelable': true });
      //     descarga.dispatchEvent(clickEvent);
      //   });
      // },
      descargarPDF() {
        return new Promise((resolve) => {
          const controllerParameters = {
              Action: 'GEN_CUADRANTE_SEMANAL_EMPLEADO_PDF',
              Fecha: this.fecha,
              PerId: this.per.PerId
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/dir", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            if (result.status >= 200 && result.status <= 299){
              let descarga = document.createElement("a");
              descarga.href = this.urlRaiz + '/Files/CuadranteSemanal.pdf' ;
              descarga.target = '_blank';
              descarga.download = 'CuadranteSemanal.pdf';
              const clickEvent = new MouseEvent('click', { 'view':window, 'bubbles': true, 'cancelable': true });
              descarga.dispatchEvent(clickEvent);
              resolve(true)
            }
          })
        })
      },
      getPendientesFromApi () {
        return new Promise((resolve) => {
          
          const controllerParameters = {
            Action: "GET_DOCS_PTES_FIRMAR_LIST",
            PerId: this.perId,
            Count: true
          };       

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
            const total = result.data.Count;

            setTimeout(() => {
              resolve({
                total
              })
            }, 1000)
          });
        })
      },
      compararArrayCuadrantes (a, b) {
        if (a.ResumenFecha){
            return 1;
        }
        else if (b.ResumenFecha){
          return -1;
        }
        else{
          if (a.Tienda > b.Tienda) {
            return 1;
          }
          if (a.Tienda < b.Tienda) {
            return -1;
          }
        }
        // a must be equal to b
        return 0;
      },
      guardarRespuestaNoFirmar() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            Respuestas: [ {PreguntasCuestionarioId: 10, respuestaUsuario: "CANCELAR"} ],
            CuestionarioId: 7,
            PerId: this.perId,
            DirId: 0
              //DirId: this.perAux != null && this.perAux.Dir != null && this.perAux.Dir.length > 0 ?
              //this.perAux.Dir[0].DirId : null
          };

          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/cuestionario/respuesta/savecartassinfirmar", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Success;

            setTimeout(() => {
                this.loading = false;
                resolve({
                  item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al guardar el cuestionario');
            }
          });
        })
      },
      formatHoras(horas) {
        // Expresión regular para encontrar bloques de horas seguidos opcionalmente por una o más letras
        const regex = /(\d{2}:\d{2} - \d{2}:\d{2})(\s*[A-Za-z]{1,2})?/g;
        let result = '';
        let match;

        // Usamos un bucle para procesar cada coincidencia encontrada
        while ((match = regex.exec(horas)) !== null) {
          // match[1] es el bloque de horas
          const hourBlock = match[1].trim();
          // match[2] es una o más letras (si existen)
          const letters = match[2] ? `<strong>${match[2].trim()}</strong>` : '';
          // Construimos el string final con horas y letras en negrita
          result += `${hourBlock} ${letters} `;
        }

        return result.trim(); // Devolvemos el string final procesado
      }

  },
}
</script>
